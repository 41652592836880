import {
  SettingsConsumer,
  SettingsProvider,
} from '@core/context/settingsContext';
import './index.css';
import ThemeComponent from '@core/theme/ThemeComponent';
import VideoDetails from 'app/student/components/Layout/VideoChallenge/DailyLesson';
import DailyQuiz from 'app/student/components/Layout/dailyChallenge';
import NotificationContainer from 'react-notifications/lib/NotificationContainer';
import StudentProfile from 'app/student/components/profile/profile';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import StudentAppModule from './app/student/app';
import CompleteProfile from './auth/completeProfile/student/completeProfile';
import Register from './auth/register/register';
import OauthRedirect from './auth/classLinkRedirect/register';
import UserLayout from './layouts/UserLayout';
// ** React Perfect Scrollbar Style
import 'react-perfect-scrollbar/dist/css/styles.css';
import TopSquad from 'app/student/components/TopSquad/TopSquad';
import Login from 'auth/login/login_new';
import Logout from 'auth/login/logout';
import WebRedirect from 'auth/redirect/WebRedirect';

function App(props) {
  const { Component } = props;
  const setConfig = Component?.setConfig ?? undefined;

  return (
    <BrowserRouter>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                <NotificationContainer />
                <Routes>
                  <Route path="/auth/login" element={<Login />} />
                  <Route path="/auth/logout" element={<Logout />} />
                  <Route path="/auth/register/:type" element={<Register />} />
                  <Route path="/auth/register/" element={<Register />} />
                  <Route
                    path="/app/hooks/classlink"
                    element={<OauthRedirect />}
                  />
                  <Route
                    path="/auth/complete_profile/"
                    element={<CompleteProfile />}
                  />
                  <Route
                    path="/app/student"
                    element={
                      <UserLayout>
                        <StudentAppModule />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/profile"
                    element={
                      <UserLayout>
                        <StudentProfile />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/leaderboard"
                    element={
                      <UserLayout>
                        <TopSquad />
                      </UserLayout>
                    }
                  />
                  {/* <Route path="/app/teacher" element={<TeacherAppModule />} /> */}
                  <Route
                    path="/app/student/video"
                    element={
                      <UserLayout>
                        <VideoDetails />
                      </UserLayout>
                    }
                  />
                  <Route
                    path="/app/student/daily-quiz"
                    element={
                      <UserLayout>
                        <DailyQuiz />
                      </UserLayout>
                    }
                  />
                  <Route path="/redirectWeb" element={<WebRedirect />} />
                  <Route path="/" element={<Login />} />
                  <Route path="*" element={<Login />} />
                </Routes>
              </ThemeComponent>
            );
          }}
        </SettingsConsumer>
      </SettingsProvider>
    </BrowserRouter>
  );
}

export default App;
