import { auth } from '../../../api/firebase';
import { cloneElement, useEffect, useState } from 'react';
import { useQuery, useSelectQueryElement } from '../../../api/hooks';
import StudentSelectAvatar from './studentSelectAvatar';

import Teacher from '../teacher/teacher';
import Parent from '../parent/parent';
import School_leader from '../school_leader/school_leader';
import Student from './student';
import { useNavigate } from 'react-router-dom';

const CompleteProfile = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [uuid, setUuid] = useState(null);
  const query = useQuery();
  const type = query.get('type');
  const email = query.get('email');
  const navigate = useNavigate();
  console.log('type', type);
  const mainComponent = useSelectQueryElement(type, [
    {
      id: 'teacher',
      component: <Teacher existingEmail={email} />,
    },
    {
      id: 'school_leader',
      component: <School_leader existingEmail={email} />,
    },
    {
      id: 'student',
      component: <Student existingEmail={email} />,
    },
    {
      id: 'parent',
      component: <Parent existingEmail={email} />,
    },
  ]);

  useEffect(() => {
    if (localStorage.getItem('uuid') && localStorage.getItem('currentUser')) {
      // const unsubscribe = auth.onAuthStateChanged(setCurrentUser);
      setCurrentUser('Student');

      setUuid(localStorage.getItem('uuid'));
      setUserType(localStorage.getItem('currentUser'));
      // return () => unsubscribe();
    } else {
      navigate(`/`);
    }
  }, []);

  return (
    <>
      {currentUser &&
        (uuid || currentUser === 'Student') &&
        cloneElement(mainComponent, {
          user: currentUser,
          uuid: uuid,
        })}
    </>
  );
};

export default CompleteProfile;
